import React from "react";

const App = () => {
  return (
    <div className="App">
      <Header />
      <AboutSection />
      <Features />
      <Products />
      <Contact />
    </div>
  );
};

const Header = () => (
  <header className="bg-green-600 text-white text-center py-12">
    <h1 className="text-4xl font-bold">Neighborhood Convenience Store</h1>
    <p className="mt-4">Your go-to spot for daily essentials and friendly service!</p>
  </header>
);

const AboutSection = () => (
  <section className="py-12 px-6 text-center">
    <h2 className="text-2xl font-semibold text-green-600">Welcome to Our Store</h2>
    <p className="mt-4 max-w-2xl mx-auto">
      Located right in the heart of your community, we’re dedicated to bringing
      you all the essentials, from fresh snacks to household items. Come visit
      us and experience the warmth of a local store!
    </p>
  </section>
);

const Features = () => (
  <section className="bg-gray-100 py-12 px-6 text-center">
    <h2 className="text-2xl font-semibold text-green-600">Our Features</h2>
    <ul className="mt-4 space-y-2">
      <li>Open 7 days a week</li>
      <li>Wide selection of snacks and drinks</li>
      <li>Fresh produce and dairy products</li>
      <li>ATM and bill payment services</li>
    </ul>
  </section>
);

const Products = () => (
  <section className="py-12 px-6 text-center">
    <h2 className="text-2xl font-semibold text-green-600">Popular Products</h2>
    <div className="mt-8 flex flex-wrap justify-center gap-6">
      <div className="bg-white shadow-md rounded-lg p-6 w-60">
        <h3 className="text-lg font-semibold">Fresh Coffee</h3>
        <p className="mt-2">Start your day with a fresh brew!</p>
      </div>
      <div className="bg-white shadow-md rounded-lg p-6 w-60">
        <h3 className="text-lg font-semibold">Local Produce</h3>
        <p className="mt-2">Fresh fruits and vegetables daily.</p>
      </div>
      <div className="bg-white shadow-md rounded-lg p-6 w-60">
        <h3 className="text-lg font-semibold">Snacks & Beverages</h3>
        <p className="mt-2">A wide range of snacks for any craving.</p>
      </div>
      <div className="bg-white shadow-md rounded-lg p-6 w-60">
        <h3 className="text-lg font-semibold">Household Essentials</h3>
        <p className="mt-2">Everything you need for daily chores.</p>
      </div>
    </div>
  </section>
);

const Contact = () => (
  <section className="bg-gray-100 py-12 px-6 text-center">
    <h2 className="text-2xl font-semibold text-green-600">Contact Us</h2>
    <p className="mt-4">📍 640 29th ave SW Cedar Rapids, Iowa</p>
    <p>📞 (319) 200-3254</p>
    <p>⏰ Open 8:00 am - 6:00 pm, every day</p>
  </section>
);

export default App;
